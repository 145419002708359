<template>
  <v-container>
    <v-row>
      <v-col class="udb-max-width">
        <div class="text-subtitle-1">{{ $t("UDB_HOME_WELCOME") }}</div>
        <div class="text-h3 mb-2">{{ $t("SITE_TITLE") }}</div>
        <!-- TODO: 更换介绍文本 -->
        <div class="text-body-1">
          {{ $t("UDB_HOME_INTRO") }}
        </div>

        <v-row class="mt-8">
          <v-col>
            <div class="text-h4">{{ $t("SITE_QUICK_TILES") }}</div>
          </v-col>
          <v-spacer />
          <v-col style="flex-grow: 0">
            <v-btn
              variant="text"
              density="comfortable"
              icon="mdi-pencil"
              color="default"
            />
          </v-col>
          <v-col cols="12">
            <v-card color="surface" class="pa-2">
              <v-row class="pa-2">
                <v-col
                  v-for="item in shortcuts"
                  :key="item.text"
                  cols="6"
                  class="pa-1"
                >
                  <NuxtLink :to="item.to" :external="item.external">
                    <v-card :style="`background-color: ${item.color}30;`">
                      <v-card-item>
                        <v-card-title
                          class="font-weight-medium text-truncate"
                          style="max-width: 100%"
                        >
                          {{ item.text }}
                        </v-card-title>
                      </v-card-item>
                      <v-card-text class="mt-4">
                        <v-row>
                          <v-spacer />
                          <v-col style="flex-grow: 0">
                            <v-icon
                              :icon="item.icon"
                              size="large"
                              :color="item.color"
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </NuxtLink>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <!-- <v-row class="mt-8">
          <v-col>
            <div class="text-h4">{{ $t("SITE_RECOMANDED_TILES") }}</div>
          </v-col>
          <v-col cols="12">
            <v-row dense>
              <v-col v-for="item in recomanded" :key="item.text" cols="6">
                <v-card elevation="0" :to="item.to" class="corner-cut-1">
                  <v-card-item>
                    <v-card-title> {{ item.text }} </v-card-title>
                  </v-card-item>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row> -->
      </v-col>
    </v-row>
  </v-container>
</template>
<script setup>
// import TxcChangeLog from "txc-change-log";
// 设置底部导航栏标题
import { useSiteBottonNavigationTitleStore } from "@/store/SiteBottonNavigationTitle";
const { t } = useI18n();

// const txcChangeLog = new TxcChangeLog({ id: 410309 });

definePageMeta({
  layout: "default",
});

// onMounted(() => {
//   const TxcChangeLog = import("txc-change-log");
//   TxcChangeLog.then((module) => {
//     // eslint-disable-next-line new-cap
//     const txcChangeLog = new module.default({ id: 410309 });
//     txcChangeLog.activateChangeLog();
//   });
// });

// TODO: 目前仅为展示用，将更换成动态值
const shortcuts = [
  {
    text: t("UDB_HOME_TITLE"),
    icon: "mdi-database-outline",
    to: "/udb",
    color: "#F4511E",
    external: false,
  },
  {
    text: "活动",
    icon: "mdi-calendar",
    to: "/events",
    color: "#4CAF50",
    external: false,
  },
  {
    text: "星图(即将到来)",
    icon: "mdi-earth",
    color: "#1976D2",
    external: false,
  },
  {
    text: "中文百科",
    icon: "mdi-book-open-variant",
    color: "#FF6F00",
    to: "https://citizenwiki.cn",
    external: true,
  },
];
// const colorList = ["#F44336", "#00ACC1", "#4CAF50", "#FF6F00"];
// for (let i = 0; i < 4; i++) {
//   shortcuts.push({
//     text: "快捷工具 " + (i + 1),
//     icon: "mdi-view-grid-outline",
//     color: colorList[i],
//   });
// }

// todo: Recomanded Tiles
// const recomanded = [
//   {
//     text: t("UDB_HOME_TITLE"),
//     icon: "mdi-database-outline",
//     to: "/udb",
//   },
//   {
//     text: "星图",
//     icon: "mdi-earth",
//   },
// ];

const titleStore = useSiteBottonNavigationTitleStore();
titleStore.setTitle("首页");
</script>
